<template>
    <div class="container">
        <div
            @click="goback"
            style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px"
        >
            <i class="el-icon-back"></i>
            返回
        </div>
        <el-space direction="vertical" alignment="start" :size="30">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span>设备基础信息</span>
                        <div>
                            <el-button
                                type="primary"
                                @click="modifyUuidBox = true"
                                text
                                >替换UUID</el-button
                            >
                            <el-button
                                type="primary"
                                @click="upgradeDeviceBox = true"
                                text
                                >强制设备升级</el-button
                            >
                        </div>
                        <el-dialog
                            v-model="modifyUuidBox"
                            :modal="true"
                            :append-to-body="true"
                            title="替换UUID"
                            width="30%"
                        >
                            <el-form class="modify-uuid">
                                <el-form-item label="旧UUID" prop="pass">
                                    <el-input
                                        disabled
                                        v-model="deviceInfo.uuid"
                                        autocomplete="off"
                                    />
                                </el-form-item>
                                <el-form-item label="新UUID" prop="pass">
                                    <el-input
                                        v-model="modifyNewUuid"
                                        autocomplete="off"
                                    />
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <span class="dialog-footer">
                                    <el-button @click="modifyUuidBox = false"
                                        >取消</el-button
                                    >
                                    <el-button
                                        type="primary"
                                        @click="ModifyUuid()"
                                        >替换</el-button
                                    >
                                </span>
                            </template>
                        </el-dialog>

                        <el-dialog
                            v-model="upgradeDeviceBox"
                            :modal="true"
                            :append-to-body="true"
                            title="强制设备升级"
                            width="30%"
                        >
                            <el-form class="modify-uuid">
                                <el-form-item label="" prop="pass">
                                    <SearchFirmwareVersion
                                        ref="searchFirmwareVersionRef"
                                        v-model="upgradeVersion"
                                        :deviceModel="deviceInfo.model"
                                    ></SearchFirmwareVersion>
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <span class="dialog-footer">
                                    <el-button @click="upgradeDeviceBox = false"
                                        >取消</el-button
                                    >
                                    <el-button
                                        type="primary"
                                        @click="UpgradeDevice()"
                                        >升级</el-button
                                    >
                                </span>
                            </template>
                        </el-dialog>
                    </div>
                </template>
                <el-form
                    :inline="true"
                    label-width="140px"
                    :model="deviceInfo"
                    class="demo-form-inline"
                    label-position="right"
                >
                    <el-row>
                        <el-col>
                            <el-form-item label="UUID">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.uuid"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="SN">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.sn"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="MAC">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.mac"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="父级UUID">
                                <el-input
                                    disabled
                                    v-model="form.device.p_uuid"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="产品型号">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.model"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="固件版本">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.version"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="创建时间">
                                <el-input
                                    disabled
                                    v-model="form.device.create_time"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="修改时间">
                                <el-input
                                    disabled
                                    v-model="form.device.update_time"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item prop="" label="名称">
                                <el-input
                                    disabled
                                    v-model="form.device.name"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="WIFI强度">
                                <el-input
                                    disabled
                                    v-model="form.device.wifi_level"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="国家码">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.country"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="时区">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.zone"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item prop="" label="充电状态">
                                <el-input
                                    disabled
                                    v-model="form.device.battery_online"
                                ></el-input>
                                <!-- <el-tag v-if="form.device.battery_online==2" class="ml-2" type="success">充电中</el-tag>
								<el-tag v-else class="ml-2" type="info">未充电</el-tag> -->
                            </el-form-item>
                            <el-form-item prop="" label="电量">
                                <el-input
                                    disabled
                                    v-model="form.device.battery_level"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="防盗状态">
                                <el-input
                                    disabled
                                    v-model="deviceInfo.theft_status"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="在线">
                                <el-lable>
                                    <el-tag
                                        v-if="form.device.online_type == 1"
                                        class="ml-2"
                                        type="warning"
                                        >MQTT</el-tag
                                    >
                                    <el-tag v-else class="ml-2" type="warning"
                                        >p2p</el-tag
                                    >
                                </el-lable>

                                <el-lable>
                                    <el-tag
                                        v-if="form.device.p2p_online == 1"
                                        class="ml-2"
                                        type="success"
                                        >在线</el-tag
                                    >
                                    <el-tag v-else class="ml-2" type="info"
                                        >离线</el-tag
                                    >
                                </el-lable>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="推送通知">
                                <template v-if="form.device.online_type == 0">
                                    <el-tag
                                        v-if="deviceInfo.notice_status == 2"
                                        class="ml-2"
                                        type="success"
                                        >开启</el-tag
                                    >
                                    <el-tag
                                        v-if="deviceInfo.notice_status != 2"
                                        class="ml-2"
                                        type="info"
                                        >关闭</el-tag
                                    >
                                </template>

                                <template v-if="form.device.online_type == 1">
                                    <el-tag
                                        v-if="deviceInfo.notice_status == 1"
                                        class="ml-2"
                                        type="success"
                                        >开启</el-tag
                                    >
                                    <el-tag
                                        v-if="deviceInfo.notice_status != 1"
                                        class="ml-2"
                                        type="info"
                                        >关闭</el-tag
                                    >
                                </template>
                            </el-form-item>
                            <el-form-item prop="" label="休眠状态">
                                <el-tag
                                    v-if="form.device.open_status == 1"
                                    class="ml-2"
                                    type="success"
                                    >开启</el-tag
                                >
                                <el-tag
                                    v-if="form.device.open_status != 1"
                                    class="ml-2"
                                    type="info"
                                    >关闭</el-tag
                                >
                            </el-form-item>
                            <el-form-item prop="" label="MQTT状态">
                                <el-select
                                    v-model="form.device.mq_online"
                                    @change="onlineStatus"
                                    placeholder=""
                                >
                                    <el-option
                                        label="在线"
                                        :value="1"
                                    ></el-option>
                                    <el-option
                                        label="不在线"
                                        :value="0"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="日志上报">
                                <template v-if="form.device.online_type == 0">
                                    <el-select
                                        v-model="deviceInfo.log_status"
                                        @change="LogStatus"
                                        placeholder=""
                                    >
                                        <el-option
                                            label="不上报"
                                            :value="1"
                                        ></el-option>
                                        <el-option
                                            label="上报"
                                            :value="2"
                                        ></el-option>
                                    </el-select>
                                </template>
                                <template v-else>
                                    <el-select
                                        v-model="deviceInfo.log_status"
                                        @change="LogStatus"
                                        placeholder=""
                                    >
                                        <el-option
                                            label="不上报"
                                            :value="0"
                                        ></el-option>
                                        <el-option
                                            label="上报"
                                            :value="1"
                                        ></el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span>设备配置信息</span>
                    </div>
                </template>
                <el-form
                    :inline="true"
                    label-width="140px"
                    :model="deviceInfo"
                    class="demo-form-inline"
                    label-position="right"
                >
                    <el-form-item label="本地IP">
                        <el-input
                            disabled
                            v-model="deviceInfo.local_ip_str"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="外网IP">
                        <el-input
                            disabled
                            v-model="deviceInfo.network_ip_str"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="" label="p2p地址">
                        <el-input
                            disabled
                            v-model="form.device.p2p_domain"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="" label="p2p IP地址">
                        <el-input
                            disabled
                            v-model="form.device.p2p_ip"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="" label="p2p端口">
                        <el-input
                            disabled
                            v-model="form.device.p2p_port"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="设备配置">
                        <el-input
                            disabled
                            type="textarea"
                            :rows="5"
                            v-model="deviceInfo.device_config"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="APP配置">
                        <el-input
                            disabled
                            type="textarea"
                            :rows="5"
                            v-model="deviceInfo.app_config"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="流媒体配置">
                        <el-input
                            type="textarea"
                            :rows="5"
                            v-model="deviceInfo.flow_config"
                        ></el-input>
                        <el-button
                            disabled
                            type="info"
                            size="mini"
                            style="position: absolute;"
                            @click="Changeflow"
                            >修改
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-space>

        <h4>设备与用户绑定关系</h4>
        <el-table border v-bind:data="list">
            <el-table-column label="关系ID">
                <template v-slot="scope">
                    {{ scope.row.device_id }}
                </template>
            </el-table-column>
            <el-table-column label="UID">
                <template v-slot="scope">
                    <router-link
                        style="color:blue;cursor: pointer;"
                        :to="
                            '/iot/userinfo/' +
                                scope.row.uid +
                                '&' +
                                deviceInfo.region +
                                '&' +
                                'online' +
                                '&' +
                                uuid
                        "
                    >
                        <span>{{ scope.row.uid }}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column label="绑定类型">
                <template v-slot="scope">
                    <span>{{
                        scope.row.bind_type == 1
                            ? '主人绑定'
                            : scope.row.bind_type == 2
                            ? '分享绑定'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="设备类型">
                <template v-slot="scope">
                    <span>{{
                        scope.row.device_type == 1
                            ? '一级设备'
                            : scope.row.device_type == 2
                            ? '二级设备'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="型号">
                <template v-slot="scope">
                    <span>{{ scope.row.model_code }}</span>
                </template>
            </el-table-column>
            <el-table-column label="免费试用记录">
                <template v-slot="scope">
                    <span>{{
                        scope.row.trial_status == 0
                            ? '未使用'
                            : scope.row.trial_status == 1
                            ? '已使用'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="绑定状态">
                <template v-slot="scope">
                    <span style="color:green" v-if="scope.row.status == 1">{{
                        '已绑定'
                    }}</span>
                    <span style="color:red" v-else-if="scope.row.status == 2">{{
                        '已解绑'
                    }}</span>
                    <span v-else>{{ '未知' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="第一次绑定时间">
                <template v-slot="scope">
                    <span>{{ changeTime(scope.row.first_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="最近一次绑定时间">
                <template v-slot="scope">
                    <span>{{ changeTime(scope.row.bind_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="解绑时间">
                <template v-slot="scope">
                    <span>{{ changeTime(scope.row.delete_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="scope">
                    <el-button
                        type="info"
                        size="mini"
                        @click="GetUserSecret(scope.row.id)"
                        >用户密钥</el-button
                    >
                    <el-button
                        v-if="scope.row.status == 1"
                        style="margin-left:0"
                        type="info"
                        size="mini"
                        @click="CreateCloud(scope.row)"
                        >开通套餐</el-button
                    >
                    <el-button
                        style="margin-left:0"
                        type="info"
                        size="mini"
                        @click="GetCloudInfo(scope.row)"
                        >云存储
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            title="开通云存储情况"
            v-model="cloudShow"
            width="1200px"
            center
        >
            <el-table border v-bind:data="cloudList">
                <el-table-column label="订单id">
                    <template v-slot="scope">
                        <span>{{ scope.row.order_id }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="设备uuid">
                    <template v-slot="scope">
                        <span>{{ scope.row.uuid }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐开始时间">
                    <template v-slot="scope">
                        <span>{{ changeTime(scope.row.start_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐结束时间">
                    <template v-slot="scope">
                        <span>{{ changeTime(scope.row.end_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐内云存储时间(天)">
                    <template v-slot="scope">
                        <span>{{ scope.row.file_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template v-slot="scope">
                        <span
                            style="color:green"
                            v-if="scope.row.status == 1"
                            >{{ '已订阅' }}</span
                        >
                        <span
                            style="color:red"
                            v-else-if="scope.row.status == 0"
                            >{{ '未订阅' }}</span
                        >
                        <span v-else>{{ '未知' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="云存储事件类型">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.is_event == 1
                                ? '事件'
                                : scope.row.is_event == 0
                                ? '全时'
                                : '未知'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订阅的app">
                    <template v-slot="scope">
                        <span>{{ scope.row.schema }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template v-slot="scope">
                        <el-button
                            v-if="scope.row.file_time !== 0"
                            type="text"
                            @click="S3KeyAnalyse(scope.row)"
                            >S3key分析
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cloudShow = false">取消</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog
            title="用户设备密钥"
            v-model="dialogFormVisible"
            width="30%"
            center
        >
            <el-form label-width="80px">
                <el-form-item label="密钥">
                    <el-input
                        v-model="userdevicesecret"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                        >确定</el-button
                    >
                </span>
            </template>
        </el-dialog>

        <el-dialog
            title="开通套餐"
            v-model="packFormVisible"
            width="30%"
            center
        >
            <el-form
                label-width="80px"
                ref="creatcloudform"
                :model="creatcloudform"
                :rules="rules"
            >
                <el-form-item label="套餐" prop="product_id">
                    <el-select v-model="creatcloudform.product_id">
                        <el-option
                            v-for="item in packList"
                            :key="item.SetMeal.code"
                            :label="item.SetMeal.name"
                            v-show="item.SetMeal.status !== 3"
                            :value="item.SetMeal.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="周期" prop="cycle">
                    <el-input-number v-model="creatcloudform.cycle" :min="1" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="packFormVisible = false">取消</el-button>
                    <el-button @click="Validate(CreateConfirm)">确认</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- s3key分析 -->
        <el-dialog
            title="S3key分析"
            v-model="s3FormVisible"
            width="1000px"
            center
        >
            <el-form
                :model="s3form"
                :inline="true"
                class="demo-form-inline"
                :rules="s3formrules"
                ref="logform"
            >
                <el-form-item label="日期" prop="date" label-width="60px">
                    <el-date-picker
                        v-model="s3form.date"
                        type="date"
                        placeholder="Pick a Date"
                        @change="GetS3key"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form
                v-if="formVisibl"
                :model="s3dataform"
                :inline="true"
                class="demo-form-inline"
            >
                <!-- <el-form-item label="appid分析:" label-width="100px">
        <el-input disabled style="width:200px" v-model="form.appid_equal" placeholder=""></el-input>
      </el-form-item> -->
                <el-form-item label="文件位置:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="s3dataform.file_brand"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="版本:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="s3dataform.version"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="区域:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="s3dataform.region"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="文件目录:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="s3dataform.file_key"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="品牌分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="s3dataform.brand_equal"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="目录分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="s3dataform.key_appid_right"
                        placeholder=""
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="位置分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="s3dataform.key_brand_right"
                        placeholder=""
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <el-table border v-bind:data="s3KeyList">
                <el-table-column label="key">
                    <template v-slot="scope">
                        <span>{{ scope.row.key }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" width="200px">
          <template v-slot="scope">
            <el-button size="mini" type="info" @click="Down(scope.row)">下载 </el-button>
          </template>
        </el-table-column> -->
            </el-table>
            <!-- <template #footer>
        <span class="dialog-footer">
          <el-button @click="s3FormVisible=false">取消</el-button>
        </span>
      </template> -->
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
import api from '../../../axios/pack'
import SearchFirmwareVersion from '../../../components/device/SearchFirmwareVersion'

export default {
	name: '',
	mixins: [],
	components: {
           SearchFirmwareVersion
       },
	props: {},
	data() {
		return {
			form: {
				device: {
					uuid: '',
					device_id: '',
					p_uuid: '',
					model_code: '',
					name: '',
					wifi_level: '',
					battery_level: '',
					battery_online: '',
					create_time: '',
					update_time: '',
					open_status: '',
					mq_online: '',
					p2p_online: '',
					p2p_domain: '',
					p2p_ip: '',
					p2p_port: '',
				},
				device_info: {
					uuid: '',
					sn: '',
					mac: '',
					version: '',
					model: '',
					local_ip: '',
					network_ip: '',
					region: '',
					country: '',
					zone: '',
					device_config: '',
					app_config: '',
					flow_config: '',
					theft_status: '',
					notice_status: '',
					log_status: '',
					pid: '',
				},
			},
			list: [],
			uuid: '',
			userdevicesecret: '',
			dialogFormVisible: false,
			packList: [],
			packFormVisible: false,
			creatcloudform: {
				product_id: '',
				uid: '',
				uuid: '',
				currency: ''
			},
			rules: {
				product_id: [{
					required: true,
					message: '请选择套餐',
					trigger: 'blur'
				}],
				currency: [{
					required: true,
					message: '请选择币种',
					trigger: 'blur'
				}],
			},
			cloudList: [],
			cloudShow: false,
			deviceInfo: [],
			currencyList: [],
			// s3分析
			formVisibl: false,
			s3FormVisible: false,
			s3dataform: {
				appid_equal: '',
				file_brand: '',
				brand_equal: '',
				version: '',
				region: '',
				file_key: '',
				key_appid_right: '',
				key_brand_right: '',
			},
			s3form: {
				date: '',
				appid: '3dab98eee85b7ae8',
				uid: '',
				uuid: '',
				file_time: ''
			},
			s3KeyList: [],
			modifyUuidBox: false,
			upgradeDeviceBox: false,
			modifyNewUuid: "",
               upgradeVersion: ''
		}
	},
	computed: {},
	watch: {},
	methods: {
		S3KeyAnalyse(item) {
			this.s3form.uuid = item.uuid
			this.s3form.uid = item.uid
			this.s3form.file_time = item.file_time
            this.s3form.appid = item.schema
			console.log('分析', this.s3form)
			this.GetS3key()
		},
		GetS3key() {
			this.s3KeyList = []
			this.s3dataform = {}
			let time = this.s3form.date ? util.dateSingle(this.s3form.date) : ''
			this.api.FileLogListAnalyse({
				rows: 10,
				prefix: 'media' + this.s3form.file_time + '/' + this.s3form.appid + '/' + this.s3form.uuid +
					'_' + this.s3form.uid + '/' + time,
				region: this.deviceInfo.region
			}).then(res => {
				if (res.data.code == 200) {
					this.s3FormVisible = true
					this.formVisibl = true
					this.s3dataform.appid_equal = res.data.data.appid_equal
					this.s3dataform.file_brand = res.data.data.file_brand
					this.s3dataform.brand_equal = res.data.data.brand_equal
					this.s3dataform.version = res.data.data.version
					this.s3dataform.region = res.data.data.region
					this.s3dataform.file_key = res.data.data.file_key
					this.s3dataform.key_appid_right = res.data.data.key_appid_right
					this.s3dataform.key_brand_right = res.data.data.key_brand_right
					this.s3KeyList = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		Down(item) {
			this.api.FileLogDown({
				brand: this.s3dataform.file_brand,
				key: item.key,
				region: this.deviceInfo.region
			}).then(res => {
				if (res.data.code == 200) {
					let imgurl = res.data.data.get_url
					let link = document.createElement('a')
					link.setAttribute('href', imgurl)
					link.setAttribute('target', '_blank')
					link.click()
					return
				}
				this.$message.error('下载失败' + res.data.msg)
			})
		},
		GetCurrency() {
			this.api.CurrencyList({
				page: 1,
				limit: 100,
			}).then(res => {
				if (res.data.code == 200) {
					this.currencyList = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetCloudInfo(item) {
			this.api.OnlineCloudInfo({
				uid: item.uid,
				uuid: item.uuid
			}).then(res => {
				if (res.data.code == 200) {
					this.cloudList = res.data.data
					this.cloudShow = true
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		CreateCloud(item) {
			this.packFormVisible = true
			this.creatcloudform.uid = item.uid
			this.creatcloudform.uuid = item.uuid
			api.PackageList({
				page: 1,
				limit: 100,
				kname: '',
				code: '',
				// product_model_code: this.form.device.model_code,
			}).then(res => {
				if (res.data.code == 200) {
					this.packList = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		Validate: function(call) {
			this.$refs['creatcloudform'].validate(res => {
				if (res && call) {
					call()
				}
			})
		},
		CreateConfirm() {
			this.api.OnlineCreateCloud(this.creatcloudform).then(res => {
				if (res.data.code == 200) {
					this.$message.success('套餐开通成功!')
					this.packFormVisible = false
					return
				}
				this.$message.error('套餐开通失败' + res.data.msg)
			})
		},
		GetInfo(item) {
			this.api.OnlineDetail({
				code: this.uuid,
				region: item ? item : ''
			}).then(res => {
				if (res.data.code == 200) {
					this.form.device = res.data.data.device
					this.form.device_info = res.data.data.device_info
					this.deviceInfo = res.data.data.device_info
					this.list = res.data.data.device_users

					// this.form.device.battery_online = this.form.device.battery_online == 1 ? '未充电' : this.form
					// 	.device.battery_online == 2 ? '正在充电' : '未知'
					// this.form.device.open_status = this.form.device.open_status == 1 ? '在线' : this.form.device
					// 	.open_status == 2 ? '不在线' : '未知'


					// this.form.device.create_time = util.dateFormat(this.form.device.create_time)
					// this.form.device.update_time = util.dateFormat(this.form.device.update_time)




					// this.deviceInfo.theft_status = this.form.device_info.theft_status == 1 ? '关闭' : this.form
					// 	.device_info.theft_status == 2 ? '开启' : '未知'
					// this.deviceInfo.notice_status = this.form.device_info.notice_status == 1 ? '关闭' : this.form
					// 	.device_info.notice_status == 2 ? '开启' : '未知'
					// this.deviceInfo.log_status = this.form.device_info.log_status==1?'不上报':this.form.device_info.log_status==2?'上报':'未知'
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		changeTime(item) {
			return util.dateFormat(item)
		},
		GetUserSecret(item) {
			this.dialogFormVisible = true
			this.api.OnlineSecret({
				id: item
			}).then(res => {
				if (res.data.code == 200) {
					this.userdevicesecret = res.data.data.secret
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		Changeflow() {
			this.api.OnlineFlowConfig({
				uuid: this.deviceInfo.uuid,
				config: this.deviceInfo.flow_config
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('修改成功')
					return
				}
				this.$message.error('修改失败' + res.data.msg)
			})
		},
		onlineStatus() {
			this.api.OnlineSatus({
				online: this.form.device.mq_online,
				uuid: this.form.device.uuid
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('修改成功')
					return
				}
				this.$message.error('修改失败' + res.data.msg)
			})
		},
		LogStatus() {
			this.api.OnlineLogSatus({
				status: this.deviceInfo.log_status,
				uuid: this.deviceInfo.uuid
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('修改成功')
					return
				}
				this.$message.error('修改失败' + res.data.msg)
			})
		},
		goback() {
			if (this.$route.params.code.split('@')[1]) {
				this.$router.push('/iot/userinfo/' + this.$route.params.code.split('@')[1])
			} else {
				this.$router.push('/iot/online')
			}
		},
		ModifyUuid() {
			if (this.modifyNewUuid == "") {
				this.$message.success('新的UUID不能为空')
				return
			}

			this.api.ModifyUuid({
				uuid: this.deviceInfo.uuid,
				new_uuid: this.modifyNewUuid
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('uuid 下发成功')
					this.modifyNewUuid = ""
					this.modifyUuidBox = false
					return
				}
				this.$message.error('调用失败:' + res.data.msg)
			})
		},
        UpgradeDevice() {
			if (this.upgradeVersion == "") {
				this.$message.error('版本号不能为空')
				return
			}
			this.api.UpgradeDevice({
				uuid: this.deviceInfo.uuid,
				model_code: this.deviceInfo.model,
                version: this.$refs.searchFirmwareVersionRef?.getVersionCode()
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('升级成功')
					this.upgradeVersion = ""
					this.upgradeDeviceBox = false
					return
				}
				this.$message.error('调用失败:' + res.data.msg)
			})
		}
	},
	filters: {},
	mounted() {
		this.uuid = this.$route.params.code.split('@')[0]
		if (this.$route.params.code.split('@')[1]) {
			this.GetInfo(this.$route.params.code.split('@')[1].split('&')[1])
		} else {
			this.GetInfo()
		}
	},
	created() {
		this.GetCurrency()
	}
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.upload-demo {
	float left
}

.el-progress {
	float right line-height 40px width 70%
}

.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.item {
	margin-bottom: 18px;
}
</style>
