<template>
    <el-select
        ref="firmwareVersionSelect"
        v-model="model"
        collapse-tags
        filterable
        clearable
        remote
        reserve-keyword
        placeholder=" "
        :remote-method="remoteFirmwareVersion"
        :loading="loading"
        @clear="clear"
        @change="handleChange"
    >
        <!-- <el-checkbox
			v-model="checked"
			@change="handleCheckAllChange"
			label="全选"
			size="large"
			style="margin-left: 20px;height: 34px;line-height: 34px;"
			:indeterminate="model.length < options.length && model.length !== 0"
		/> -->
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
</template>

<script>
export default {
    name: 'SearchFirmwareVersion',
    props: ['modelValue', 'deviceModel'],
    emits: ['update:modelValue', 'keyupQuery'],
    data() {
        return {
            loading: false,
            checked: false,
            options: []
        }
    },
    created() {
        this.remoteFirmwareVersion()
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        getVersionCode() {
            return this.options.find(v => v.value === this.model)?.version_code || ''
        },
        remoteFirmwareVersion(query) {
            this.loading = true
            this.api
                .FirmwareList({
                    page: 1,
                    limit: 100,
                    kname: '',
                    product_model_code: this.deviceModel || '',
                    version_code: query || ''
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.options = res.data.data.data.map((item) => ({
                            value: item.code,
                            label: `${item.package_name}（${item.version_code}）`,
                            version_code: item.version_code
                        }))
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$message.error('获取失败' + res.data.msg)
                })
        },
        handleCheckAllChange() {
            if (this.checked) {
                this.model = this.options.map((item) => item.value)
            } else {
                this.model = []
            }
        },
        clear() {
            this.remoteFirmwareVersion('')
        },
        handleChange() {
            this.$emit('selectQuery')
        }
    }
}
</script>
